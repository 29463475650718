<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入学生姓名" v-model="QueryInfo.StudentName" clearable @clear="QueryinfoRefundList"> 
                        <el-button slot="append" icon="el-icon-search" @click="QueryinfoRefundList"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <el-table :data="RefundList" :row-class-name="TableRowClassName" @row-click="RefundHandleClick" v-loading="Loading" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="姓名" prop="studentName"></el-table-column>
                <el-table-column label="缴费金额（￥）" prop="paid"></el-table-column>
                <el-table-column label="消耗金额（￥）" prop="useMoney"></el-table-column>
                <el-table-column label="退费金额（￥）" prop="refundMoney"></el-table-column>
                <el-table-column label="申请时间" prop="createTime"></el-table-column>
                <el-table-column label="经办人" prop="userName"></el-table-column>
                <el-table-column label="确认时间" prop="makeSureTime"></el-table-column>
                <el-table-column label="确认人" prop="makeSureName"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="RefundTotal">
            </el-pagination>
        </el-card>
        <MakeSureRefundDialog :visible.sync="MakeSureRefundDialogVisible" :requestdata="true" :refundid='RefundDataID' :makesurerefund="CanOperation" :cancelrefund="CanOperation"
        v-on:ChangeCloseMakeSureRefund="QueryRefundList">
        </MakeSureRefundDialog>
    </div>
</template>


<script>
import { GetRefundList } from '@/assets/js/api/payapi';
import MakeSureRefundDialog from '../Dialog/makesurerefunddialog.vue';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    components:{
        MakeSureRefundDialog
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                State:-1,
                StudentName:'',
                Start:1,
                Count:SpreadCount[0],
            },
            RefundList:[],
            RefundTotal:0,
            Loading:false,
            MakeSureRefundDialogVisible:false,
            RefundDataID:0,
            CanOperation:false
        }
    },
    created(){
        this.QueryRefundList()
    },
    methods:{
        TableRowClassName({row}) {
            if (row.makeSureID === 0) 
            {
                return 'warning-row';
            } 
            return '';
        },
        QueryinfoRefundList(){
            this.QueryInfo.Start = 1;
        this.QueryInfo.Count = this.spreadCount[0];
            this.QueryRefundList()
        },
        async QueryRefundList(){
            this.Loading = true
            var ret = await GetRefundList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.RefundList = ret.data.refunds
                this.RefundTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryRefundList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryRefundList()
        },
        RefundHandleClick(row){
            this.RefundDataID = row.id
            if(row.state == 0){
                this.CanOperation = true
            }
            else{
                this.CanOperation = false
            }
            this.MakeSureRefundDialogVisible = true
        },
    }
}
</script>

<style lang="less" scoped>
    .el-table /deep/ .warning-row {
        background: #F56C6C;
    }
</style>