import axios from 'axios';

//提交支付信息
export async function PostPay(payinfo){
  var res = await axios.post('Pay/v1/Pay', payinfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//查询支付信息通过学生ID和状态
export async function GetPayList(queryinfo){
  var res = await axios.get('Pay/v1/PayByStudentID', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//通过ID查询支付信息
export async function GetPayByID(id){
  var res = await axios.get('Pay/v1/PayByID', {params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}


//确认提交信息
export async function PutPayMakeSure(payinfo){
  var res = await axios.put('Pay/v1/PayMakeSure', payinfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//删除提交信息
export async function DeleteCancelPay(payinfo){
  var res = await axios.delete('Pay/v1/CancelPay', {params: payinfo})
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//通过支付ID获取缴费课程记录
export async function GetCourseNumberByPay(id){
  var res = await axios.get('Pay/v1/CourseNumberByPay', {params: { payid:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//提交退费信息
export async function PostRefundInfo(refundInfo){
  var res = await axios.post('Pay/v1/RefundInfo', refundInfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//查询退费信息
export async function GetRefundList(queryinfo){
  var res = await axios.get('Pay/v1/RefundQuery', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//通过退费ID获取退费记录
export async function GetRefundByRefundID(id){
  var res = await axios.get('Pay/v1/RefundData', {params: { refundid:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//删除退费信息
export async function DeleteRefundData(id){
  var res = await axios.delete('Pay/v1/CancelRefund', {params: { refundid:id}})
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//确认退费信息
export async function PutRefundMakeSure(payinfo){
  var res = await axios.put('Pay/v1/RefundMakeSure', payinfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//提交纠正的支付信息
export async function PostPayCorrectMistake(payinfo){
  var res = await axios.post('Pay/v1/PayCorrectMistake', payinfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//按条件查询缴费信息
export async function GetPayQuery(queryinfo){
  queryinfo.payways = queryinfo.payways + ''
  queryinfo.status = queryinfo.status + ''
  var res = await axios.get('Pay/v1/PayQuery', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//按条件查询缴费统计信息
export async function GetPaysStatistisc(queryinfo){
  queryinfo.payways = queryinfo.payways + ''
  queryinfo.status = queryinfo.status + ''
  var res = await axios.get('Pay/v1/PaysStatistisc', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

