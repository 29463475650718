<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="申请退费中" :visible.sync="makeSureRefundDialogVisible"  v-alterELDialogMarginTop="{marginTop:'7vh'}"
    :close-on-click-modal="false" width="60%" @close="closeDialog">
        <el-row>
            <el-descriptions class="desc_div" size="medium" :column="3" border v-loading="loading">
                <el-descriptions-item label="常规课时">{{ this.refundData.normal }}</el-descriptions-item>
                <el-descriptions-item label="赠送课时">{{ this.refundData.free }}</el-descriptions-item>
                <el-descriptions-item label="消耗课时">{{ this.refundData.use }}</el-descriptions-item>
                <el-descriptions-item label="退费课时">{{ this.refundData.normal - this.refundData.use }}</el-descriptions-item>
                <el-descriptions-item label="课时单价(￥)">{{ this.refundData.unitprice }}</el-descriptions-item>
            </el-descriptions>
        </el-row>
        <div class="markesure_div">
            <el-row>
                <el-descriptions :column="2"  border :gutter="6" v-for="item  in this.refundData.RefundObjectArr" :key="item.ID" :contentStyle="CS" 
                :label-style="LS" v-loading="loading">
                    <el-descriptions-item label="名称">{{item.name}}</el-descriptions-item>
                    <el-descriptions-item label="数量">{{item.count}}</el-descriptions-item>
                    <el-descriptions-item label="价格">{{item.price}}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{item.remark}}</el-descriptions-item>
                </el-descriptions>
            </el-row>
        </div>
        <el-divider></el-divider>
        <el-row v-loading="loading">
            <el-col :span="8">
                <span class="payable_span_div">缴费金额：{{ this.refundData.Paid}} ￥</span>
            </el-col>
            <el-col :span="8">
                <span class="discount_span_div">消耗金额：{{ this.refundData.UseMoney}} ￥</span>
            </el-col>
            <el-col :span="8">
                <span class="user_span_div">经办人：{{ this.refundData.UserName}}</span>
            </el-col>
        </el-row>
        <el-row v-loading="loading">
            <el-col :span="8">
                <span class="paid_span_div">退费金额：{{ this.refundData.RefundMoney}} ￥</span>
            </el-col>
            <el-col :span="16">
                <span class="paid_span_div">金额大写：{{ this.refundData.RefundMoneyToChies}} </span>
            </el-col>
        </el-row>
        <span slot="footer" >
            <el-button v-if="this.cancelrefund" type="danger" :disabled="loading" @click="cancelRefund">取消申请</el-button>
            <el-button v-if="this.makesurerefund" type="primary" :disabled="loading" @click="makeSureRefund">确定</el-button>
        </span>
    </el-dialog>    
</template> 

<script>
import { ToChies } from '@/assets/js/systemvariable.js'
import { GetRefundByRefundID, DeleteRefundData, PutRefundMakeSure } from '@/assets/js/api/payapi'
export default {
    components:{

    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        cancelrefund:{
            type: Boolean,
            default:true
        },
        makesurerefund:{
            type: Boolean,
            default:false
        },
        requestdata:{
            type: Boolean,
            default:false
        },
        refundinfodata:Object,
        refundid:{
            type:Number,
            default:0
        }
    },
    computed:{
        makeSureRefundDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            CS: {
                'text-align': 'center', 
                'min-width': '8vw', 
                'word-break': 'break-all' 
            },
            LS: {
                'color': '#000',
                'text-align': 'center',
                'font-weight': '600',
                'height': '40px',
                'background-color': '#F2F6FC',
                'min-width': '110px',
                'word-break': 'keep-all'
            },
            refundData:{
                ID:0,
                Payable:0.0,
                Paid:0.0,
                Discount:0.0,
                WayID:0,
                SealTypeID:0,
                TypeID:0,
                CourseNumberID:0,
                UserID:0,
                Remark:'',
                State:0,
                StateName:'',
                CreateTime:'',
                MakeSureTime:'',
                CancelTime:'',
                CompleteTime:'',
                Normal:0.0,
                Free:0.0,
                Use:0.0,
                UserName:'',
                TypeName:'',
                WayName:'',
                SealTypeName:'',
                StudentName:'',
                RefundMoneyToChies:'',
                RefundObjectArr:[
                    {
                        ID:0,
                        Name:'',
                        Count:0.0,
                        Price:0.0,
                        Remark:'',
                    }
                ]
            },
            loading:false,
        }
    },
    watch:{
        makeSureRefundDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.requestdata){
                    this.getRefundData()
                }
                else
                {
                    this.refundData.ID = this.refundinfodata.ID
                    this.refundData.Paid = this.refundinfodata.Paid
                    this.refundData.UseMoney = this.refundinfodata.UseMoney
                    this.refundData.RefundMoney = this.refundinfodata.RefundMoney
                    this.refundData.RefundMoneyToChies = ToChies(this.refundData.RefundMoney)
                    this.refundData.UserName = this.refundinfodata.UserName
                    this.refundData.normal = this.refundinfodata.normal
                    this.refundData.free = this.refundinfodata.free
                    this.refundData.use = this.refundinfodata.use
                    this.refundData.unitprice = this.refundinfodata.unitprice
                    this.refundData.RefundObjectArr = this.refundinfodata.RefundObjects
                }
               
            }
        }
    },
    methods:{
        closeDialog(){
            this.makeSureRefundDialogVisible = false
        },
        async getRefundData(){
            this.loading = true
            var ret = await GetRefundByRefundID(this.refundid)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.refundData.ID = ret.data.id
                this.refundData.PayID = ret.data.payID
                this.refundData.Paid = ret.data.paid
                this.refundData.UseMoney =  ret.data.useMoney
                this.refundData.RefundMoney = ret.data.refundMoney
                this.refundData.RefundMoneyToChies = ToChies(ret.data.refundMoney)
                this.refundData.UserName = ret.data.userName
                this.refundData.normal = ret.data.normal
                this.refundData.free = ret.data.free
                this.refundData.use = ret.data.use
                this.refundData.RefundObjectArr = ret.data.refundObjects
                var objectmoney = 0
                for(var i = 0;i < this.refundData.RefundObjectArr.length; i++){
                    objectmoney += this.refundData.RefundObjectArr[i].price
                }
                this.refundData.unitprice = this.calculateRefundMoney(this.refundData.Paid - objectmoney, this.refundData.normal).toFixed(2)
            }
            else
            {
                this.$message.error(ret.message);
            }            
        },
        calculateRefundMoney(money, coursenumber){
            return money /coursenumber 
        },
        async cancelRefund(){
            const confirmResult = await this.$confirm('确认取消退费申请,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }

            this.loading = true
            var ret = await DeleteRefundData(this.refundData.ID)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success("取消退费申请成功")
                this.$emit("ChangeCloseMakeSureRefund")
                this.closeDialog()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async makeSureRefund(){
            const confirmResult = await this.$confirm('确认退费申请,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.loading = true
            var ret = await PutRefundMakeSure(this.refundData)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('确认退费成功');
                this.$emit("ChangeCloseMakeSureRefund")
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.closeDialog()
        },
    }
}
</script>

<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
    .desc_div{
        margin: 16px;
    }
    .markesure_div{
        height:40vh;
        overflow: auto;
        overflow-x:hidden;
        margin: 16px;
    }
    .paid_span_div{
        font-size: 20px;
        font-weight: 600;
        color: #F56C6C;
    }
    .payable_span_div{
        font-size: 20px;
        font-weight: 300;
        color: #409EFF;
    }
    .discount_span_div{
        font-size: 20px;
        font-weight: 300;
        color: #67C23A;
    }
    .user_span_div{
        font-size: 20px;
        font-weight: 300;
        color: #909399;
    }
</style>